import toast from "react-hot-toast";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { changeMemberStatusServ, createMemberServ, deleteMemberServ, getMemberServ, getSingleMemberServ, updateMemberServ } from "../services/member.service";
import { userActions } from "../slice/user.slice";
import { CHANGE_MEMBER_STATUS, CREATE_MEMBER, DELETE_MEMBER, GET_MEMBERS, GET_SINGLE_MEMBER, UPDATE_MEMBER } from "../types/member.types";
import { uiActions } from "../slice/ui.slice";

export function* createMemberSaga(action) {
    try {
        // Use toast.promise to handle loading, success, and error states
        const member = yield call(() =>
            toast.promise(
                createMemberServ(action.payload),  // Call the create member service
                {
                    loading: 'Creating member...',  // Message shown while creating
                    success: 'Member created successfully',  // Message shown on success
                    error: 'Failed to create member',  // Message shown on failure
                }
            )
        );

        if (member?.data) {
            const prevMembers = yield select(userActions.selectors.getMembers);
            yield put(userActions.actions.setMembersSlice([member?.data, ...prevMembers]));

            // Optionally, you can remove this toast if it's redundant:
        }
    } catch (error) {
        toast.error(error?.response?.data?.message)
        console.log("Error creating member:", error);
    }
}


export function* getMemberSaga(action) {
    try {
        const members = yield getMemberServ(action.payload);

        yield put(userActions.actions.setMembersSlice(members?.data));
    } catch (err) {
        console.log("Error fetching members:", err?.message);
    }
}

export function* getSingleMemberSaga(action) {
    try {
        const member = yield getSingleMemberServ(action.payload);
        yield put(userActions.actions.setSelectedMemberSlice(member?.data));
    } catch (err) {
        console.log("Error fetching members:", err?.message);
    }
}


export function* updateMemberSaga(action) {
    try {

        const updatedMember = yield call(() =>
            toast.promise(
                updateMemberServ(action.payload.formData, action.payload.id),  // Call the create member service
                {
                    loading: 'Updating member...',
                    success: 'Member updated successfully',
                    error: 'Failed to update member',
                }
            )
        );

        if (updatedMember?.data) {
            const prevMembers = yield select(userActions.selectors.getMembers);
            const updatedMembers = prevMembers.map(member =>
                member._id === updatedMember.data._id ? updatedMember.data : member
            );
            yield put(uiActions.actions.setMemberPopup(false))
            yield put(userActions.actions.setEditMemberDataSlice({}))
            yield put(userActions.actions.setMembersSlice(updatedMembers));

            toast.success("Member updated successfully");
        }
    } catch (err) {
        console.log("err", err?.message)
    }
}

export function* changeMemberStatusSaga(action) {
    try {

        const payload = {
            userActive: action.payload.status
        }
        const updatedMember = yield call(() =>
            toast.promise(
                changeMemberStatusServ(payload, action.payload.id),
                {
                    loading: 'Changing Status...',
                    success: 'Status Changed Successfully',
                    error: 'Failed to Change Status',
                }
            )
        );

        if (updatedMember?.data) {
            const prevMembers = yield select(userActions.selectors.getMembers);
            const updatedMembers = prevMembers.map(member =>
                member._id === updatedMember.data._id ? updatedMember.data : member
            );
            yield put(userActions.actions.setMembersSlice(updatedMembers));
        }
    } catch (err) {
        console.log("err", err?.message)
    }
}

export function* deleteMemberSaga(action) {
    try {
        const member = yield deleteMemberServ(action.payload?.memberId);
        if (member?.data) {
            toast.success("Member Deleted Successfully");
            const prevMembers = yield select(userActions.selectors.getMembers);
            const updatedMembers = prevMembers.filter(v => v._id !== member.data._id);
            yield put(userActions.actions.setMembersSlice(updatedMembers));
        }

    } catch (err) {
        console.log("Error deleting user:", err?.message);
    }
}



export function* watchMemberAsync() {
    yield takeEvery(CREATE_MEMBER, createMemberSaga);
    yield takeEvery(GET_MEMBERS, getMemberSaga);
    yield takeEvery(GET_SINGLE_MEMBER, getSingleMemberSaga);
    yield takeEvery(DELETE_MEMBER, deleteMemberSaga);
    yield takeEvery(UPDATE_MEMBER, updateMemberSaga);
    yield takeEvery(CHANGE_MEMBER_STATUS, changeMemberStatusSaga);
}
