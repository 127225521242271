import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sharedActions } from '../../../../redux/slice/shared.slice';
import { userActions } from '../../../../redux/slice/user.slice';
import { CHANGE_MEMBER_STATUS, DELETE_MEMBER, GET_MEMBERS } from '../../../../redux/types/member.types';
import MyDialog from '../../../shared/MyDialog/MyDialog';
import MyTable from '../../../shared/MyTable/MyTable';
import { handleDownloadCsv } from '../../../utils/csv';
import styles from '../../user/user.module.scss';
import { getMemberColumns } from '../helper';
import MemberForm from './MemberForm';
import MemberToolbar from './MemberToolbar';
import SubscriptionForm from './Subscription/SubscriptionForm';
import { uiActions } from '../../../../redux/slice/ui.slice';

const Member = () => {

    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");

    const [userStatus, setUserStatus] = useState("Active");
    const handleChangeStatus = (event) => {
        setUserStatus(event.target.value);
        const payload = {
            searchTerm: searchTerm,
            userStatus: event.target.value
        }
        dispatch({ type: GET_MEMBERS, payload })
    };
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        const payload = {
            searchTerm: value,
            userStatus: userStatus
        }
        dispatch({ type: GET_MEMBERS, payload })
    };

    useEffect(() => {
        const payload = {
            searchTerm: "",
            userStatus: "Active"
        }
        dispatch({ type: GET_MEMBERS, payload })
    }, [dispatch])

    const members = useSelector(userActions.selectors.getMembers)
    const isModalOpen = useSelector(uiActions.selectors.getMemberPopup);
    const handleModelOpen = () => {
        dispatch(uiActions.actions.setMemberPopup(true))
        dispatch(sharedActions.actions.setCardState("member"))
    }

    const handleModelClose = () => {
        dispatch(uiActions.actions.setMemberPopup(false))
        dispatch(userActions.actions.setEditMemberDataSlice({}))
        dispatch(sharedActions.actions.setCardState("all"))
    }

    const [menuState, setMenuState] = useState({
        anchorEl: null,
        selectedRow: null,
    });

    const editUserData = useSelector(userActions.selectors.getEditMemberData)
    const handleOpenMenu = (event, row) => {
        setMenuState({
            anchorEl: event.currentTarget,
            selectedRow: row,
        });
    };

    const handleClose = () => {
        setMenuState({
            anchorEl: null,
            selectedRow: null,
        });
    };



    const handleClickItem = (key, row) => {
        // eslint-disable-next-line default-case
        switch (key) {
            case "Delete":
                const payload = {
                    memberId: row?._id,
                };
                dispatch({ type: DELETE_MEMBER, payload });
                break;
            case "Edit":
                dispatch(userActions.actions.setEditMemberDataSlice(row))
                handleModelOpen()
                break;
            case "Subscription":
                handleSubscriptionModalOpen();
                dispatch(userActions.actions.setEditMemberDataSlice(row))

                break;
            case "Activate":
                dispatch({ type: CHANGE_MEMBER_STATUS, payload: { id: row?._id, status: true } })
                break;
            case "Deactivate":
                dispatch({ type: CHANGE_MEMBER_STATUS, payload: { id: row?._id, status: false } })
                break;
        }
        handleClose();
    };

    const [subscriptionModel, setSubscriptionModel] = useState(false)
    const handleSubscriptionModalOpen = () => {
        setSubscriptionModel(true)
    }
    const handleSubscriptionModalClose = () => {
        setSubscriptionModel(false)
    }


    const memberColumns = getMemberColumns({ menuState, handleClose, handleClickItem, handleOpenMenu })

    return (
        <>
            <Grid container className={styles.userContainer}>
                <Grid item xs={12}>
                    <MemberToolbar
                        searchTerm={searchTerm}
                        placeHolder={"Search Member"}
                        handleSearch={handleSearch}
                        text={"Add Member"}
                        handleClickBtn1={handleModelOpen}
                        handleClickBtn2={() => handleDownloadCsv(members, memberColumns, `Member ${dayjs().format("DD_MM_YYYY")}`)}
                        handleFilterChange={handleChangeStatus}
                        userStatus={userStatus}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MyTable
                        checkboxSelection={false}
                        columns={memberColumns}
                        rows={members}
                        id={(row) => row?._id}
                    />
                </Grid>
                <MyDialog isFullScreen={true} title={"Add Member"} open={isModalOpen} handleClose={handleModelClose} maxWidth='xl' >
                    <MemberForm editUserData={editUserData} handleClose={handleModelClose} />
                </MyDialog>

                <MyDialog isFullScreen={false} title={"Add Subscription"} open={subscriptionModel} handleClose={handleSubscriptionModalClose} maxWidth='md' >
                    <SubscriptionForm userData={editUserData} handleClose={handleSubscriptionModalClose} />
                </MyDialog>
            </Grid>
        </>
    )
}

export default Member
