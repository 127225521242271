import { Bookmark, ContentPasteOff, CreditCard, Healing, LocationOn, People, Store, Tour, VerifiedUser } from '@mui/icons-material';
import { Badge, Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'yup';
import { authActions } from '../../../../../redux/slice/auth.slice';
import { ADD_VISIT } from '../../../../../redux/types/shared.types';
import tableStyles from '../../../../shared/MyTable/myTable.module.scss';
import TableSkeleton from '../../../../shared/MyTable/TableSkeleton';
import InputField from '../../../../shared/RHF/InputField';
import RHFCheckBox from '../../../../shared/RHF/RHFCheckBox';
import SelectField from '../../../../shared/RHF/SelectField';
import { formatDateTime } from '../../../../utils/date';
import styles from '.././member.module.scss'; // Import the modular SCSS file
import { formVisitSchema, paymentOptions, YupVisitSchema } from './helper';

const MemberInfo = ({ member, handleClose }) => {

    const user = useSelector(authActions.selectors.getCurrentUser);
    const [isOverride, setIsOverRide] = useState(false);
    const dispatch = useDispatch();
    let visitSchema = object(YupVisitSchema(isOverride));
    const { control, handleSubmit, formState: { errors, isValid }, watch } = useForm(formVisitSchema(visitSchema));

    const handleCreateVisit = (data) => {
        const payload = {
            memberId: member?._id,
            ...data
        }
        dispatch({ type: ADD_VISIT, payload });
        handleClose();
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (Object.keys(member)?.length) {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 5000);

            return () => clearTimeout(timer);
        } else {
            setLoading(false);
        }
    }, [member]);


    useEffect(() => {
        setIsOverRide(watch("overridePurchase"))
    }, [watch])


    return (
        <Box className={styles.memberInfoCard}>
            {loading ? (
                <TableSkeleton rows={4} cols={1} />
            ) :
                Object.keys(member)?.length ?
                    <form className={styles.formContainer} onSubmit={handleSubmit(handleCreateVisit)}>
                        <Grid container spacing={2} alignItems="center">
                            {!member?.userActive &&
                                <Grid item textAlign={'center'} display={'flex'} justifyContent={'center'} xs={12} className={styles.memberHeader}>
                                    <Typography textAlign={'center'} color='error' variant="h6">Your account is inactive. Please activate to continue shopping.</Typography>
                                </Grid>
                            }
                            <Grid item textAlign={'center'} xs={12} className={styles.memberHeader}>
                                <Typography variant="h5">{`${member?.surnameMember} ${member?.forenameMember} `}</Typography>
                            </Grid>


                            <Grid item xs={6} display={'flex'} flexDirection={'column'} gap={1}>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <Bookmark fontSize='14px' className={styles.icon} /> <strong> Last Subscription : </strong> {formatDateTime(member?.subscription?.paidDate)}
                                </Typography>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <Tour fontSize='14px' className={styles.icon} /> <strong> Last Visit : </strong> {formatDateTime(member?.lastVisitDate)}
                                </Typography>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <People fontSize='14px' className={styles.icon} /> <strong> No. of Household  : </strong> {member?.totalNumberOfPeople}
                                </Typography>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <LocationOn fontSize='14px' className={styles.icon} /> <strong> No. of Visits (This Week) : </strong> {member?.visitCounts}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} display={'flex'} flexDirection={'column'} gap={1}>
                                <Box className={styles.infoItem}>
                                    <VerifiedUser fontSize='14px' className={styles.icon} />
                                    <Typography variant="subtitle1" className={styles.infoItem}>
                                        User Status:
                                    </Typography>
                                    <Badge sx={{ ml: 4 }} badgeContent={member?.userActive ? "Active" : 'InActive'} color={member?.userActive ? "success" : 'error'} />
                                </Box>



                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <CreditCard fontSize='14px' className={styles.icon} /> Card Number: {member?.cardNumber}
                                </Typography>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    <Healing fontSize='14px' className={styles.icon} /> Allergy Details: {member?.allergyDetails}
                                </Typography>

                                <Box className={styles.infoItem}>
                                    <VerifiedUser fontSize='14px' className={styles.icon} />
                                    <Typography variant="subtitle1" className={styles.infoItem}>
                                        Proof of Id :
                                    </Typography>
                                    <Badge sx={{ ml: 4 }} badgeContent={member?.proofOfId ? "True" : 'False'} color={member?.proofOfId ? "success" : 'error'} />
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <InputField control={control} errors={errors} name={'visitAmount'} placeHolder={'Amount'} required={true} label={"Amount"} type={'text'} />
                            </Grid>
                            {watch("overridePurchase") ?
                                <Grid item xs={6}>
                                    <InputField control={control} errors={errors} name={'notes'} placeHolder={'Notes'} required={true} label={"Notes"} type={'text'} />
                                </Grid>
                                :
                                <Grid item xs={6}>
                                </Grid>
                            }

                            <Grid item xs={6}>
                                <SelectField control={control} errors={errors} name={'paymentMethod'} options={paymentOptions} label={"Payment Method"} />
                            </Grid>
                            <Grid item xs={6}>
                                <RHFCheckBox control={control} errors={errors} name={'overridePurchase'} label={"Override Purchase"} />
                            </Grid>
                            <Grid item xs={3}>
                                <RHFCheckBox control={control} errors={errors} name={'validatedProof'} label={"Validated Proof"} />
                            </Grid>
                            <Grid item xs={3}>
                                <RHFCheckBox control={control} errors={errors} name={'validatedPhoto'} label={"Validated Photo"} />
                            </Grid>
                            <Grid item xs={3}>
                                <RHFCheckBox control={control} errors={errors} name={'explainedDisclamer'} label={"Explained The Disclamer"} />
                            </Grid>
                            <Grid item xs={3}>
                                <RHFCheckBox control={control} errors={errors} name={'issuedByMe'} label={`Food is issued by ${user?.firstName}`} />
                            </Grid>
                            <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={1}>
                                <Typography variant="subtitle1" className={styles.infoItem}>
                                    Photos
                                </Typography>
                                <Grid container spacing={2}>
                                    {[member?.photo1].map((photo, index) => (
                                        photo && (
                                            <Grid item xs={3} key={index}>
                                                <img
                                                    src={photo}
                                                    alt={`Preview ${index + 1}`}
                                                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                />
                                            </Grid>
                                        )
                                    ))}
                                </Grid>

                            </Grid>
                            <Grid display={'flex'} justifyContent={'flex-end'} item xs={12}>
                                <Button
                                    sx={{ p: 1, px: 3 }}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Store />}
                                    type='submit'
                                    disabled={!member?.userActive}
                                >
                                    Confirm Shop/Visit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    :
                    <Box className={tableStyles.noData} sx={{ minHeight: '200px' }}><ContentPasteOff /> User Not Found</Box>
            }
        </Box>
    );
};

export default MemberInfo;
