import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

const SelectField = ({ control, errors, name, label, options }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl error={!!errors[name]} fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} size="small">
            {options?.map((option, idx) => (
              <MenuItem key={idx} value={option?.value}>
                {option?.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectField;
