import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, string } from "yup";

export const YupVisitSchema = (isOverride) => {
    return {
        visitAmount: string()
            .required("Amount is required")
            .matches(/^\d+$/, "Amount must be a valid number"),
        notes: isOverride ? string().required() : string().notRequired(),
        validatedProof: boolean()
            .oneOf([true], "You must validate proof"),
        validatedPhoto: boolean()
            .oneOf([true], "You must validate the photo"),
        explainedDisclamer: boolean()
            .oneOf([true], "You must explain the disclaimer"),
        issuedByMe: boolean()
            .oneOf([true], "You must issue this yourself")
    }
}

export const formVisitSchema = (visitSchema) => {
    return {
        resolver: yupResolver(visitSchema),
        mode: 'onChange',
        defaultValues: {
            visitAmount: "",
            notes: '',
            overridePurchase: false,
            paymentMethod: 'cash',
            validatedProof: false,
            validatedPhoto: false,
            explainedDisclamer: false,
            issuedByMe: false
        }
    }
}


export const paymentOptions = [
    { label: "Cash", value: 'cash' },
    { label: "Card", value: 'card' },
]