import { Add, Launch, Search } from "@mui/icons-material";
import { Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import styles from '../../user/user.module.scss';
const MemberToolbar = ({
    searchTerm,
    handleSearch,
    text,
    isDisabled = false,
    handleClickBtn1,
    handleClickBtn2,
    placeHolder,
    handleFilterChange,
    userStatus
}) => {



    return (
        <Grid container gap={'10px'} alignItems="center" justifyContent="space-between">
            <Grid item xs={6} md={4} >
                <TextField
                    label={placeHolder}
                    size="small"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchBar}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={6} md={2} >
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userStatus}
                        onChange={handleFilterChange}
                        size="small"
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Inactive"}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'} item xs={12} md={5} >

                {text &&
                    <Button
                        sx={{ p: 1 }}
                        onClick={handleClickBtn1}
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<Add />}
                        disabled={isDisabled}
                    >
                        {text}
                    </Button>
                }
                <Button
                    sx={{ p: 1 }}
                    onClick={handleClickBtn2}
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<Launch />}
                    disabled={isDisabled}
                >
                    Export CSV
                </Button>
            </Grid>

        </Grid>
    );
};

export default MemberToolbar;
